import * as React from 'react';

import { AudiPlatformProvider, audiDarkTheme } from '@audi/audi-ui-react';
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from '@feature-hub/core';

import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import AudiFooter from './components/audi-feature-app-footer';
import { AudiFooterResponse } from './types/audi-footer-response.types';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { FeatureAppEnvironment } from '@feature-hub/core/lib/cjs/feature-app-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { ReactFeatureApp } from '@feature-hub/react';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { getData } from './services/api';

export interface AudiFooterDependencies extends FeatureServices {
  readonly 'locale-service': LocaleServiceV1;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 's2:logger': Logger;
}

/**
 * - `api` is an URL defining the footer resource. A corresponding JSON schema is shown in the README.
 * - `hideSocialMedia` indicates whether social media links should be displayed or not. Default: `false`.
 *
 * **Example:**
 *
 * ```jsx
 * <feature-app
 *   id="<unique-identifier>"
 *   src="audi-footer.umd.js"
 *   server-src="<absolute-url-to>/audi-footer.commonjs.js"
 *   config='{"api":"<api-base-url>/audi-footer.json", "hideSocialMedia": true}'
 * ></feature-app>
 * ```
 */
export type AudiFooterConfig = {
  api: string;
  hideSocialMedia?: boolean;
};

type AudiFooterDefinition = FeatureAppDefinition<
  ReactFeatureApp,
  AudiFooterDependencies,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any
>;

function parseFeatureAppConfig(
  env: FeatureAppEnvironment<AudiFooterDependencies, AudiFooterConfig>,
): AudiFooterConfig {
  try {
    const api = env.config.api;

    if (typeof api === 'undefined') {
      throw new Error('Could not find `config.api`.');
    }

    return {
      api,
      hideSocialMedia: env.config.hideSocialMedia,
    };
  } catch (error) {
    error.message = `[${env.featureAppId}] Bad parsing of feature app config. ${error.message}`;
    throw error;
  }
}

const featureAppDefinition: AudiFooterDefinition = {
  create: (env) => {
    const asyncSsrManager: AsyncSsrManagerV1 =
      env.featureServices['s2:async-ssr-manager'];
    const serializedStateManager: SerializedStateManagerV1 =
      env.featureServices['s2:serialized-state-manager'];
    const logger: Logger = env.featureServices['s2:logger'];
    const { countryCode } = env.featureServices['locale-service'];
    const referenceServiceScopeManager = env.featureServices[
      'audi-footnote-reference-service'
    ] as AudiFootnoteRefernceServiceScopeManagerInterfaceV3;

    const referenceServiceManager = referenceServiceScopeManager
      ? referenceServiceScopeManager.getDefaultScopeRefService()
      : undefined;

    try {
      const { api, hideSocialMedia } = parseFeatureAppConfig(env);

      let audiFooterError: Error = null;
      let audiFooterResponse: AudiFooterResponse = null;

      if (typeof asyncSsrManager !== 'undefined') {
        // Ignore callback from Jest coverage because it is not invoked here.
        // Also, it is not possible to move it outside the `create`
        // factory function since it relies on `audiFooterResponse`.
        /* istanbul ignore next */
        if (typeof serializedStateManager !== 'undefined') {
          serializedStateManager.register(() =>
            JSON.stringify(audiFooterResponse),
          );
        }
        asyncSsrManager.scheduleRerender(
          (async (): Promise<void> => {
            try {
              audiFooterResponse = await getData(api);
            } catch (error) {
              error.message = `[${env.featureAppId}] Could not fetch data. ${error.message}`;
              audiFooterError = error;
              logger.error(error);
            }
          })(),
        );
      } else {
        const serializedAudiFooterData =
          serializedStateManager?.getSerializedState();

        if (typeof serializedAudiFooterData !== 'undefined') {
          try {
            audiFooterResponse = JSON.parse(serializedAudiFooterData);
          } catch (error) {
            error.message = `[${env.featureAppId}] Bad parsing of serialized state. ${error.message}`;
            audiFooterError = error;
            logger.error(error);
          }
        }
      }

      return {
        render(): React.ReactNode {
          return (
            <AudiPlatformProvider theme={audiDarkTheme}>
              <AudiFooter
                api={api}
                countryCode={countryCode}
                data={audiFooterResponse}
                error={audiFooterError}
                featureAppID={env.featureAppId}
                hideSocialMedia={hideSocialMedia}
                referenceServiceManager={referenceServiceManager}
              />
            </AudiPlatformProvider>
          );
        },
      };
    } catch (error) {
      logger.error(error);

      return {
        render(): React.ReactNode {
          return (
            <AudiPlatformProvider theme={audiDarkTheme}>
              <AudiFooter
                api=""
                countryCode={countryCode}
                data={null}
                error={error}
                featureAppID={env.featureAppId}
                referenceServiceManager={referenceServiceManager}
              />
            </AudiPlatformProvider>
          );
        },
      };
    }
  },

  dependencies: {
    externals: { react: '^16.13.0' },
    featureServices: {
      'locale-service': '1.1.0',
      's2:logger': '^1.0.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      'audi-footnote-reference-service': '3.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
    },
  },
};

export default featureAppDefinition;
