import * as React from 'react';
import {
  getClassNamesForLinks,
  getLinkTarget,
  onCookieModalLinkClick,
} from '../../services/helpers';
import { Link } from '../../types/audi-footer-response.types';
import styled from 'styled-components';

type LegalLinksProps = {
  countryCode?: string;
  links: Link[];
};

const LegalLinksStyledWrapper = styled.div`
  flex-grow: 1;
  margin-right: var(--one-footer-space-xl);

  & > ul {
    display: inline-flex;
    flex-flow: row wrap;
    list-style: none;
    margin: 0 0 calc(var(--one-footer-space-l) - var(--one-footer-space-s));
    padding: 0;
  }

  & > ul > li:not(:last-child) {
    padding-right: var(--one-footer-space-l);
  }

  & > ul > li > a {
    display: inline-block;
    margin-bottom: var(--one-footer-space-s);
  }
`;

const LegalLinks: React.FC<LegalLinksProps> = ({ countryCode, links }) => {
  return (
    <LegalLinksStyledWrapper>
      <ul>
        {links.map((link) => {
          return (
            <li key={`${link.Target}${link.Text}${link.Url}`}>
              <a
                className={getClassNamesForLinks(link, countryCode)}
                /* nemo specific cookie modal link click */
                {...(!!link.Type &&
                link.Type === 'cookie-settings-layer' &&
                countryCode !== 'US'
                  ? { onClick: onCookieModalLinkClick }
                  : {})}
                href={link.Url}
                target={getLinkTarget(link)}
              >
                {link.Text}
              </a>
            </li>
          );
        })}
      </ul>
    </LegalLinksStyledWrapper>
  );
};

export default LegalLinks;
