import * as React from 'react';

import {
  AudiFootnoteReferenceServiceInterfaceV3,
  EnumerableFootnote,
} from '@volkswagen-onehub/audi-footnote-reference-service';
import { Text, audiDarkTheme, responsiveStyles } from '@audi/audi-ui-react';
import { useEffect, useState } from 'react';

import { AudiFooterResponse } from '../types/audi-footer-response.types';
import Categories from './categories/categories';
import LanguageSwitch from './language-switch/language-switch';
import LegalLinks from './legal-links/legal-links';
import SocialMedia from './social-media/social-media';
import ToTopLink from './to-top-link/to-top-link';
import { getData } from '../services/api';
import styled from 'styled-components';
import { useFootnoteReferenceServiceTextParserHook } from '../services/hooks';

type AudiFooterProps = {
  api: string;
  countryCode: string;
  data: AudiFooterResponse | null;
  error: Error;
  hideSocialMedia?: boolean;
  referenceServiceManager?: AudiFootnoteReferenceServiceInterfaceV3;
  featureAppID: string;
};

export const breakpointM = audiDarkTheme.breakpoints.m;
export const breakpointXL = audiDarkTheme.breakpoints.xl;

const OneFooterStyled = styled.div`
  --one-footer-color-black: ${({ theme }): string =>
    theme.colors.utility.black};
  --one-footer-color-white: ${({ theme }): string =>
    theme.colors.utility.white};
  --one-footer-side-spacing: 16px;
  --one-footer-space-xs: var(
    ${({ theme }): string => theme.responsive.spacing.xs}
  );
  --one-footer-space-s: var(
    ${({ theme }): string => theme.responsive.spacing.s}
  );
  --one-footer-space-m: var(
    ${({ theme }): string => theme.responsive.spacing.m}
  );
  --one-footer-space-l: var(
    ${({ theme }): string => theme.responsive.spacing.l}
  );
  --one-footer-space-xl: var(
    ${({ theme }): string => theme.responsive.spacing.xl}
  );
  --one-footer-space-xxl: var(
    ${({ theme }): string => theme.responsive.spacing.xxl}
  );
  --one-footer-space-xxxl: var(
    ${({ theme }): string => theme.responsive.spacing.xxxl}
  );
  --one-footer-side-spacing: 16px;
  background: var(--one-footer-color-black);
  box-sizing: border-box;
  color: var(--one-footer-color-white);
  ${({ theme }): string =>
    responsiveStyles(
      {
        '--one-footer-side-spacing': {
          l: '60px',
          m: '40px',
          s: '28px',
          xl: '96px',
          xxl: 'calc((100% - 1728px) / 2)',
        },
      },
      theme.breakpoints,
    )}
  /* stylelint-disable */
  padding: var(--one-footer-space-l) var(--one-footer-side-spacing) var(--one-footer-space-xxl);
  /* stylelint-enable */

  & *,
  & *::after,
  & *::before {
    box-sizing: border-box;
  }

  & a,
  & select {
    /* stylelint-disable */
    color: var(--one-footer-color-white);
    cursor: pointer;
    font-family: var(
        ${({ theme }): string => theme.responsive.typography.copy2.fontFamily}
      ),
      sans-serif;
    /* stylelint-enable */
    font-size: var(
      ${({ theme }): string => theme.responsive.typography.copy2.fontSize}
    );
    font-stretch: var(
      ${({ theme }): string => theme.responsive.typography.copy2.fontStretch}
    );
    line-height: var(
      ${({ theme }): string => theme.responsive.typography.copy2.lineHeight}
    );
    text-decoration: none;
  }

  & a:hover,
  & select:hover {
    color: ${({ theme }): string => theme.colors.utility.neutral[70]};
  }

  & a:focus-visible,
  & select:focus-visible {
    outline: ${({ theme }): string => theme.colors.utility.neutral[20]} auto 1px;
  }

  & [data-is-overview-link=\'true\'] {
    ${({ theme }): string =>
      responsiveStyles(
        {
          display: { m: 'none' },
        },
        theme.breakpoints,
      )}
  }
`;

const OneFooterStyledToolbar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const OneFooterStyledToolbarBorderTop = styled.div`
  border-top: 1px solid
    ${({ theme }): string => theme.colors.utility.neutral[10]};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: var(--one-footer-space-s) 0 0;
  padding: var(--one-footer-space-l) 0 0;
  ${({ theme }): string =>
    responsiveStyles(
      {
        margin: { m: '0' },
        padding: { m: 'var(--one-footer-space-l) 0 0' },
      },
      theme.breakpoints,
    )}
`;

const AudiFooter: React.FC<AudiFooterProps> = (props) => {
  const {
    api,
    countryCode,
    data,
    error,
    hideSocialMedia,
    referenceServiceManager,
    featureAppID,
  } = props;

  const [componentError, setComponentError] = useState<Error>(error);
  const [componentData, setComponentData] = useState<AudiFooterResponse>(data);
  const [footnotes, setFootnotes] = useState([]);

  const disclaimerText = useFootnoteReferenceServiceTextParserHook({
    footnotes,
    htmlString:
      componentData && componentData.Disclaimer ? componentData.Disclaimer : '',
    referenceServiceManager,
  });

  useEffect(() => {
    if (referenceServiceManager) {
      referenceServiceManager.registerCallback(
        (_footnotes: EnumerableFootnote[]) => {
          setFootnotes(_footnotes);
        },
      );
    }

    return (): void => {
      if (referenceServiceManager) {
        referenceServiceManager.removeFootnoteReferences();
      }
    };
  }, [referenceServiceManager]);

  useEffect(() => {
    if (componentError === null && componentData === null) {
      (async (): Promise<void> => {
        try {
          const response = await getData(api);
          setComponentData(response);
        } catch (error) {
          setComponentError(error);
        }
      })();
    }
  }, [api, componentError, componentData]);

  if (componentError) {
    return <div>Error</div>;
  }

  if (componentData === null) {
    return <div>Loading...</div>;
  }

  return (
    <OneFooterStyled
      data-fefa-custom-id={featureAppID}
      data-module="one-footer"
    >
      <OneFooterStyledToolbar>
        <ToTopLink label={componentData.ToTopLabel} />
      </OneFooterStyledToolbar>
      <Categories categories={componentData.Categories} />
      {!hideSocialMedia &&
        componentData.SocialMedia &&
        componentData.SocialMedia.length > 0 && (
          <SocialMedia socialMedia={componentData.SocialMedia} />
        )}
      <OneFooterStyledToolbarBorderTop>
        <Text as="span" spaceInlineEnd="xxl" spaceStackEnd="m" variant="copy2">
          {componentData.Copyright}
        </Text>
        <LegalLinks
          countryCode={countryCode}
          links={componentData.LegalLinks}
        />
        {componentData.LanguageLinks &&
          componentData.LanguageLinks.length > 0 && (
            <LanguageSwitch links={componentData.LanguageLinks} />
          )}
      </OneFooterStyledToolbarBorderTop>
      {componentData &&
        componentData.Disclaimer &&
        componentData.Disclaimer.length > 0 && (
          <Text as="p" spaceStackStart="l" variant="copy2">
            <span
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{
                __html: disclaimerText.replace(
                  /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
                  'illegalscript',
                ),
              }}
            />
          </Text>
        )}
    </OneFooterStyled>
  );
};

export default AudiFooter;
