import classNames from 'classnames';
const footnotesAnchorTextSelector = '.audi-footnote-anchor__text';
const referenceSelector = '.audi-j-footnote-reference';

export const getClassNamesForLinks: (link, countryCode?: string) => string = (
  link,
  countryCode,
) => {
  return classNames({
    'audi-cookie-modal-link':
      link.Type === 'cookie-settings-layer' && countryCode !== 'US',
    'j-action-usabilla': link.Type === 'feedback',
    'nm-j-poa': link.Type === 'poa',
    'nm-layerLink': link.Target === '_layer',
    'nm-pageOpen': link.Target === '_inpage',
    'optanon-show-settings':
      link.Type === 'cookie-settings-layer' && countryCode === 'US',
  });
};

export const getLinkTarget: (link) => string = (link) => {
  let target = link.Target || '_self';

  /* nemo layer link specific settings */
  if (!link.Target || link.Target === '_inpage' || link.Target === '_layer') {
    target = '_self';
  }

  return target;
};

export function onCookieModalLinkClick(event: React.MouseEvent): void {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return;
  }

  /* istanbul ignore next */
  event.preventDefault();

  /* istanbul ignore next */
  if (!window['Bootstrapper'] || window['Bootstrapper'] === null) {
    return;
  }

  /* istanbul ignore next */
  if (
    window['Bootstrapper'].gateway &&
    window['Bootstrapper'].gateway.openModal
  ) {
    window['Bootstrapper'].gateway.openModal();
    document.body.scrollTop = 0;
  } /* istanbul ignore next */ else {
    console.error('Unable to call Bootstrapper.gateway.openModal');
  }
}

export function extractFootnoteIdFromString(rawKey_: string): string {
  let key = rawKey_;
  const index = key.indexOf('#');

  if (index !== -1) {
    key = key.substr(index + 1);
  }

  return key;
}

export function replaceTextWithFootnoteNumber(
  element_: HTMLElement,
  number_: number,
): void {
  if (!element_.dataset.fixedText && number_ > 0) {
    const textContainer = element_.querySelector(footnotesAnchorTextSelector);
    if (textContainer) {
      textContainer.innerHTML = number_ + '';
    }
  }
}

export function setDataSetonElement({
  element_,
  name,
  id,
  referenceId,
}: {
  element_: HTMLLinkElement;
  name: string;
  id: string;
  referenceId: string;
}): void {
  element_.href = '#' + id;
  element_.dataset[name] = referenceId;
}

export const loopOverReferences = (
  htmlString: string,
  callBackPerId: (id: string, ref?: HTMLLinkElement) => void,
): string => {
  const div = document.createElement('div');
  div.innerHTML = htmlString;
  const allReferencesFromString = div.querySelectorAll(referenceSelector);
  allReferencesFromString.forEach((reference: HTMLLinkElement) => {
    const footnoteIDFromHref = extractFootnoteIdFromString(reference.href);
    const footnoteIDFromDataAttribute = reference.dataset.referenceId
      ? extractFootnoteIdFromString(reference.dataset.referenceId)
      : undefined;
    const footnoteID = footnoteIDFromDataAttribute
      ? footnoteIDFromDataAttribute
      : footnoteIDFromHref;
    callBackPerId(footnoteID, reference);
  });

  return div.innerHTML;
};
